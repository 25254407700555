/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lz-string@1.5.0/libs/lz-string.min.js
 * - /npm/marked@13.0.1/marked.min.js
 * - /npm/dompurify@3.1.5/dist/purify.min.js
 * - /gh/mwt/hash-viewer@e8fb79ed75fb826fa8b13f51b946ef7aae0d2f50/assets/js/404.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
